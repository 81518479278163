import React, { ChangeEvent, useRef, useState } from 'react';
import './App.css';
import { Grid, InputAdornment, TextField } from "@mui/material";
import CopyIcon from "@mui/icons-material/ContentCopy"
import Dropzone from "react-dropzone";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


function App() {

  const [inputText, setInputText] = useState('')
  const [outputText, setOutputText] = useState('')

  const inputRef = useRef<HTMLInputElement>()
  const outputRef = useRef<HTMLInputElement>()

  const [b64Error, setB64Error] = useState(false)

  console.log(window.location.href)

  const handleB64TextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    try {
      const binString = atob(e.target.value)
      const decoded = new TextDecoder().decode(
        Uint8Array.from(
          binString, (m, n) => m.codePointAt(0) || 0
        )
      )
      setOutputText(e.target.value)
      handleDecodedBase64Bytes(decoded)
      setB64Error(false)
    } catch (er) {
      setB64Error(true)
    }
  }

  const handleInputTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setInputText(e.target.value)

    handleEncodedText(
      btoa(
        String.fromCodePoint(
          ...(new TextEncoder().encode(e.target.value))
        )
      )
    )
  }

  const handleCopyInput = () => {
    navigator.clipboard.writeText(inputText)
    toast(
      'Copied to clipboard!',
      {type: 'success', autoClose: 1000}
    )
  }

  const handleCopyOutput = () => {
    navigator.clipboard.writeText(outputText)
    toast(
      'Copied to clipboard!',
      {type: 'success', autoClose: 1000}
    )

  }

  const handleDecodedBase64Bytes = (bytes: string) => {
    setInputText(bytes)
    inputRef.current!!.value = bytes
  }

  const handleEncodedText = (text: string) => {
    setOutputText(text)
    outputRef.current!!.value = text
  }

  const handleDrop = (acceptedFiles: File[], _: any, __: any) => {
    const reader = new FileReader()
    reader.onload = (ev) => {

      if (ev.target?.result) {
        outputRef.current!!.value = btoa(ev.target.result.toString())
      }
    }
    reader.readAsBinaryString(acceptedFiles[0])
  }

  const inputSize = new Blob([inputText]).size
  const outputSize = new Blob([outputText]).size
  const relDiff = inputSize > 0 && outputSize > 0
    ? Math.round((outputSize / inputSize - 1) * 100)
    : 0

  return (
    <div className="App">
      <header>
        <h1>Base64 Converter</h1>
      </header>

      <Grid container justifyContent={ 'center' } columnSpacing={{xs: 2}}>
        <Dropzone onDrop={ handleDrop }>
          { ({getRootProps}) => (
            <Grid item { ...getRootProps() } xs={ 7 } sx={ {textAlign: 'left'} }>
              <TextField
                InputProps={{startAdornment: (
                    <InputAdornment position='start'>
                      <CopyIcon
                        onClick={handleCopyInput}
                        sx={{cursor: 'pointer', position: 'absolute', right: '1rem', bottom: '1rem'}} />
                    </InputAdornment>
                  )}}
                multiline={ true }
                label={ 'Enter text or Base64-String or drop file to be converted' }
                onChange={ handleInputTextChange }
                variant={ 'filled' }
                inputRef={ inputRef }
                rows={ 10 }
                sx={ {borderRadius: '10px', width: '100%', marginBottom: '2rem'} }
              >
              </TextField>
            </Grid>
          ) }
        </Dropzone>
        <Grid item xs={ 1 } sx={{textAlign: 'left'}}>{ inputSize } bytes</Grid>
      </Grid>
      <Grid container justifyContent={ 'center' } columnSpacing={{xs: 2}}>
        <Grid item xs={ 7 }>
          <TextField
            InputProps={{startAdornment: (
              <InputAdornment position='start'>
                <CopyIcon
                  onClick={handleCopyOutput}
                  sx={{cursor: 'pointer', position: 'absolute', right: '1rem', bottom: '1rem'}} />
              </InputAdornment>
              )}}
            multiline={ true }
            label={ 'Enter Base64-String' }
            onChange={ handleB64TextChange }
            variant={ 'filled' }
            rows={ 10 }
            inputRef={ outputRef }
            error={ b64Error }
            sx={ {borderRadius: '10px', width: '100%', marginBottom: '2rem'} }
          >
          </TextField>
        </Grid>
        <Grid item xs={ 1 } sx={ {textAlign: 'left'} }>
          <div>{ outputSize } bytes ({ `${ relDiff >= 0 ? '+' : '-' }${ relDiff }%` })</div>
        </Grid>
      </Grid>
      <ToastContainer />
    </div>
  );
}

export default App;
